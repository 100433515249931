import React from "react"
import Hero from "../components/Hero"
import "../assets/terms.css"
import Seo from "../components/seo"
import { graphql } from "gatsby"
const TermsAndConditions = () => {
  return (
    <>
      <Seo title="Terms and Conditions" />
      <Hero
        title="DISCLAIMER AND TERMS OF USE"
        subTitle="Terms and Conditions for Users, Agents, Advertisers and Customer
          Relationship Marketing (CRM) Providers"
      />
      <div className="container mt-5">
        <ol type="1">
          <li>
            <h6>TERMS AND CONDITIONS OF</h6>
            <p>
              In these terms and conditions for users (“Terms of Use”) the terms
              “we,” “our,” “us,” and “website” refers to our website
              (onlinepropertydeals.com), OPD, Property Deals (PD) and its
              subsidiaries, employees, officers, agents, affiliates or assigned
              parties. By accessing and using this Website, you are agreeing to
              be bound by the Web site's Terms of Use, Disclaimer, and the
              Privacy Policy (together with the “Terms”), all applicable laws
              and regulations, and agree that you are responsible for compliance
              with any applicable local laws. If you do not agree with any of
              these Terms, your sole option is to immediately cease your use of
              this Website; applicable copyright and trademark law protects the
              materials contained in this Website. You may not use the Website
              and may not accept these Terms if (a) you are not of eighteen (18)
              years of age, or (b) you are a person who is either barred or
              otherwise legally prohibited from receiving or using the Website
              under the laws of the country in which you are a resident or from
              which you access or use the Website.
            </p>
          </li>
          <ol type="1">
            <li>
              <h6>USE & RESTRICTIONS</h6>
              <p>
                In logging on or making use of our website, you for all time
                accept and undertake to ensure that you will not:
              </p>
              <ol type="1">
                <li>
                  Use any automated device, software process or means to access,
                  retrieve, to move from one place to another, or index our
                  website or any content on our website;
                </li>
                <li>
                  Transmit spam, chain letters, content, junk email, surveys,
                  religious, political, obscene, indecent, profane, or other
                  mass messaging, whether commercial in nature or not
                </li>
                <li>
                  Use up our website or any subject matter from our website in
                  any way which we in our exclusive uphold as not sensible
                  and/or not for the intent which we made accessible
                </li>
                <li>
                  Violate and/or encroach upon the products, domain, or rights
                  of another, or upon the rights of any person, including
                  copyright, trade classified, privacy right, or any other
                  intellectual property or patented right
                </li>
                <p>
                  We reserve the right to exercise whatever means we deem
                  necessary to prevent unauthorised access to our use of the
                  Website, including but not limited to, instituting
                  technological barriers, or reporting your conduct to any
                  person or entity.
                </p>
              </ol>
            </li>
            <li>
              <h6>YOUR RESPONSIBILITIES</h6>
              <p>
                We are a real estate advertising, marketing, and brokerage
                agency and our specialized and experienced experts provide a
                service whereby intermediaries may advertise and you may see
                posted details at the same time with other content hosted and
                developed by us. Agents and third parties are responsible for
                preparing their photos, logos, and information.
              </p>
              <p>
                Our website hosts users and information after we verify them.
                You are responsible for making your own Logos, Photos, and
                descriptions we grant no promise and accept no responsibility
                for the accuracy or completeness of any information contained
                within the information.
              </p>
            </li>
            <li>
              <h6>LIMITATIONS</h6>
              <ol type="1">
                <li>
                  Under whatsoever circumstances, will Online Property Deals be
                  liable for any damages including, without limitation, damages
                  for loss of data or profit, or due to business interruption,
                  arising out of the use or inability to use the materials on
                  the Website, even if one of our authorised representatives has
                  been notified orally or in writing of the possibility of such
                  damage.
                </li>
                <li>
                  We will not be liable for any loss or damage arising under or
                  in connection with:
                </li>
                <li>
                  Any failures due to software or Internet errors or
                  unavailability, or any other circumstances beyond our
                  reasonable control
                </li>
                <li>
                  any loss of your password or account, if caused by a
                  breakdown, error, loss of power, or otherwise caused by or to
                  your computer system and/or your account
                </li>
                <li>The use of, or inability to use our website</li>
                <li>
                  Any direct, consequential, special, or punitive loss, damage,
                  costs, and expenses
                </li>
                <li>Loss of profit</li>
                <li>Loss of business</li>
                <li>Loss of reputation</li>
                <li>Depletion of goodwill</li>
                <li>Loss of, damage to, or corruption of data</li>
                <p>
                  If you inquire about a service or commodity on this Website,
                  you acknowledge and agree that your details will be sent by
                  email directly to you. We do not accept any liability for any
                  subsequent communications that you receive directly from any
                  third party
                </p>
              </ol>
            </li>
            <li>
              <h6>REVISIONS AND ERRATA</h6>
              <p>
                The items, data, and information appearing on the Website could
                contain technical, typographical, or photographic errors. We do
                not guarantee that any of the items, data, and information on
                its website are accurate, complete, or up-to-date. We may well
                make modifications and/or amendments to the items, data, and
                information contained on the Website at any time without notice.
              </p>
            </li>
            <li>
              <h6>ACCESSIBILITY OF WEBSITE</h6>
              <p>
                Online Property Deals endeavours to warrant that our website and
                the services are always available to you but cannot guarantee
                that either the Website or the services will operate
                continuously, without interruptions, or be fault-free. On
                occasion, necessary maintenance or upgrade work entails making
                the Website and its services unavailable without notice, but we
                take aim to keep interruption and idle time to a minimum. We
                accept no liability for any interruption or loss of service. We
                reserve the absolute right to modify, suspend or terminate any
                part of our website or the services, including your access to
                it.
              </p>
            </li>
            <li>
              <h6>LINKS AND THIRD PARTIES</h6>
              <p>
                Our website may encompass links, hyperlinks, and indicators to
                third-party products, services, and/or websites that are not
                associated with Us. We have no control over the products,
                services, or websites of these third parties and We do not
                warrant or assume responsibility for them. Our website may also
                contain advertising from third parties and we are not
                responsible, nor do we make any warranties or representations
                for any deceptive, ambiguous, or inaccurate advertisements which
                are the exclusive responsibility of the advertiser.
              </p>
              <p>
                Any links or advertisements on our website should not be taken
                as an endorsement by us of any kind. Furthermore, our website
                encompasses information provided by third parties and we accept
                no responsibility, nor do we make any guarantees or
                representations for any deceptive, ambiguous, or inaccurate
                information in this material. You agree to release us from any
                claims or disputes of any kind arising from or in any way
                connected to such disputes with third parties.
              </p>
              <p>
                By using the Website, you grant us an irrevocable, worldwide,
                royalty-free licence to commercials, copy, licence to other
                persons, use and adapt for any purpose any material you generate
                or submit to make use of the Website.
              </p>
              <p>
                We do not guarantee that the content, links, or sub-domains
                taken in by OPD, or associated with it will always be accessible
                to you. Data on our publications, posts, inserts, information,
                content should not be regarded as a substitute for professional
                legal, financial advice.
              </p>
            </li>
            <li>
              <h6>SITE TERMS OF USE MODIFICATIONS</h6>
              <p>
                We may revise these Terms of Use and any such Terms for the
                Website at any time without notice. By using this Website, you
                are agreeing to be bound by the Terms of Use.
              </p>
            </li>
            <li>
              <h6>CONTRIBUTIONS</h6>
              <p>
                In these Terms of Use “Contributions” means any information
                including data, text, video, still images, audio, or other
                material that we have permitted you to host, share, publish,
                post, store, or upload on our website. We may at any time,
                without liability to you, remove, alter, or disable access to
                any or all your Contributions in our sole discretion without
                prior notice to you. Without limiting the previous sentence, we
                may remove or disable access to any or all your Contributions if
                we consider that:
              </p>
              <ol type="1">
                <li>
                  Those Contributions are in breach of any law or regulation
                </li>
                <li>
                  Those Contributions infringe the intellectual property rights
                  of any third party
                </li>
                <li>
                  It is required to do so by a regulatory body or any relevant
                  authority pursuant to an interim or final take-down notice
                </li>
                <li>Those Contributions</li>
                <li>Misleading or deceptive</li>
                <li>
                  Inappropriate having regard to the purpose of our website
                </li>
                <li>Likely to cause offence</li>
                <li>Materially incorrect</li>
                <li>Obscene</li>
                <li>Defamatory</li>
                <li>
                  Otherwise, unlawful and/or against the customs or norms of the
                  region in which this Website is referred to
                </li>
                <li>
                  Corrupted, due to the presence of a virus or other disabling
                  code
                </li>
              </ol>
              <p>
                To the extent that any Contributions are proprietary in nature,
                you grant us a worldwide, nonexclusive, royalty-free, perpetual,
                transferable, and irrevocable license to use, reproduce, modify,
                adapt, translate, distribute, publish, create derivative works
                from and display and publicly perform your Contributions
                throughout the world in any medium, whether currently in
                existence or not.
              </p>
            </li>
            <li>
              <h6>INTELLECTUAL PROPERTY</h6>
              <p>
                Unless otherwise expressly stated, all contents of the Website
                are copyrights, trademarks, trade dress, and/or other
                intellectual property owned, controlled, or licensed by us, or
                one of our affiliates or by third parties who have licensed
                their materials to us and are protected by the applicable laws.
              </p>
              <p>
                We, together with our suppliers and licensors expressly reserve
                all intellectual property rights in all text, programs,
                products, processes, technology, content, and other materials,
                which appear on this Website. Access to this Website does not
                confer and shall not be considered as conferring upon anyone any
                license under any of our or any third party's intellectual
                property rights. Any use of this Website or its contents,
                including copying or storing it or them in whole or part, other
                than for your own personal, non-commercial use, is prohibited
                without our permission. You may not modify, distribute, or
                re-post anything on this Website for any purpose.
              </p>
              <p>
                Our names and logos and all related product and service names,
                design marks, and slogans are the trademarks or service marks of
                us or our licensors. No trademark or service mark license is
                granted in connection with the materials contained on this
                Website.
              </p>
              <p>
                Access to this Website does not authorize anyone to use any
                name, logo, or mark in any manner whatsoever.
              </p>
            </li>
            <li>
              <h6>ELECTRONIC COMMUNICATIONS</h6>
              <p>
                When you use the Website or send emails to us, you are
                communicating with us electronically. You consent to receive
                electronically any communications related to your use of this
                Website. We will communicate with you by email or by posting
                notices on this Website. You agree that all agreements, notices,
                disclosures, and other communications that are provided to you
                electronically satisfy any legal requirement that such
                communications be in writing. All notices from us intended for
                receipt by you shall be deemed delivered and effective when sent
                to the email address you provide on the Website for your
                account.
              </p>
            </li>
            <li>
              <h6>INDEMNITY</h6>
              <p>
                You agree to indemnify and hold us and our affiliates (and our
                officers, agents, partners, and employees) against any and all
                loss, liability, claim or demand, including reasonable
                attorney's fees, arising out of, or in connection with your use
                of and access to our Website or making Contributions not in
                accordance with the Terms. The materials on our Website are
                provided on an “as is” and “as available” basis and we make no
                warranties, expressed or implied, and hereby refuse and negate
                all other warranties, including without limitation, implied
                warranties or conditions of merchantability, fitness for a
                particular purpose, or non-infringement of intellectual property
                or other violation of rights. Further, we do not warrant or make
                any representation concerning the accuracy, likely results, or
                reliability of the use of the materials on the Website or
                otherwise relating to such materials or on any site linked to
                this Website.
              </p>
            </li>
          </ol>
        </ol>
        <li>
          <h6>GOVERNING LAW</h6>
          <p>
            These Terms of Use and your access to the Website are subject to and
            governed by the laws of the Lebanese Republic. You agree to submit
            to the exclusive authority of the Courts of the Lebanese Republic.
          </p>
          <p>
            If any term of the Agreement is or may become for any reason invalid
            or unenforceable at law, the validity and enforceability of the
            remainder will not be affected.
          </p>
        </li>
        <li>
          <h6>CONTACT US</h6>
          <p>
            Online Property Deals (onlinepropertydeals.com) subscribers,
            members, and agents are licensed real estate brokers, developers,
            and hotel apartment providers. Our advertisers are contractually
            obligated to only list properties that are available for sale or
            lease with the proper authority from the owner and all other
            required governing bodies. Properties listed on our website should
            be a fair and accurate portrayal of the property itself and the
            proposed transaction. To report any suspected fraudulent or
            misleading property postings on our site please send us an email
            with the details to info@onlinepropertydeals.com
          </p>
          <p>
            If you have any queries, complaints, or recommendations about these
            Terms of Use and/or Disclaimer, please contact us at the following
            address: info@onlinepropertydeals.com.
          </p>
        </li>
      </div>
    </>
  )
}
export const query = graphql`
  {
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image
        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`
export default TermsAndConditions
